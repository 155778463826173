<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
    rounded="sm"
    spinner-variant="primary"
  >
    <b-row>
      <b-col>
        <b-card>
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2 flex-grow-1">
              {{ startupName }}
            </h3>
          </b-card-header>
        </b-card>
        <form-wizard
          :hide-buttons="readonly || ($route.name === 'startup-application-question' && !selectStartup)"
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="() => $router.back()"
        >
          <tab-content
            v-for="(section, i) in questions"
            :key="i"
            :title="i"
          >
            <b-row
              v-for="(question, index) in section"
              :key="index"
            >
              <b-col>
                <b-form-group
                  class="mb-2"
                >
                  <label
                    :class="`h5`"
                    :for="`question-${index}`"
                    style="white-space: pre-line;"
                  >
                    &#9654; {{ question.question }} {{ question.is_required ? '*' : '' }} <b-badge variant="light-secondary">{{ question.input_type }}</b-badge>
                  </label>
                  <template #description>
                    <small
                      v-if="!readonly && question.input_type === 'Document' && question.programs_applicationresponsetables[0].response "
                      class="font-weight-bold"
                    >File already uploaded. Uploading new file will replace existing file.</small>
                  </template>
                  <b-input-group class="mt-50">
                    <b-form-textarea
                      v-if="question.input_type === 'Long text'"
                      :id="`answer-${index}`"
                      v-model="question.programs_applicationresponsetables[0].response"
                      :readonly="readonly"
                      placeholder="Your answer"
                      required
                    />
                    <v-select
                      v-else-if="question.input_type === 'Dropdown' || question.input_type === 'Multiselect'"
                      :id="`answer-${index}`"
                      v-model="question.programs_applicationresponsetables[0].response"
                      :style="readonly?'pointer-events:none;filter:grayscale(100%)':''"
                      :multiple="question.input_type === 'Multiselect'"
                      :options="JSON.parse(question.options)"
                      append-to-body
                      class="w-100"
                      label="Choose from the following"
                      placeholder="Select from list"
                    />
                    <b-form-file
                      v-else-if="readonly !== true && question.input_type==='Document'"
                      :id="`answer-${index}`"
                      v-model="files[question.id]"
                      drop-placeholder="Drop file here..."
                      placeholder="Choose a file or drop it here... [Max: 5MB]"
                      @input="checkFileSize(files[question.id], question.id)"
                    />
                    <b-form-input
                      v-else
                      :id="`answer-${index}`"
                      v-model="question.programs_applicationresponsetables[0].response"
                      :readonly="readonly"
                      :type="question.input_type === 'Number' ? 'number' : 'text'"
                      placeholder="Your answer"
                      required
                    />
                    <b-input-group-append
                      v-if="question.input_type==='Document' && question.programs_applicationresponsetables[0].response && !files[question.id]"
                    >
                      <Promised
                        :promise="getLinkFromS3(question.programs_applicationresponsetables[0].response)"
                      >
                        <template #pending>
                          <b-button
                            class="mr-50"
                            disabled
                            size="sm"
                            variant="primary"
                          >
                            <feather-icon icon="ClockIcon" />
                          </b-button>
                        </template>
                        <template #default="data">
                          <b-button
                            v-b-tooltip="'View Uploaded File'"
                            :href="data"
                            class="mr-50"
                            target="_blank"
                            variant="primary"
                          >Open
                          </b-button>
                        </template>
                        <template #rejected>
                          <span v-b-tooltip="`File not found`">
                            <b-button
                              class="mr-50"
                              disabled
                              size="sm"
                              variant="outline-danger"
                            ><feather-icon icon="AlertTriangleIcon" /></b-button>
                          </span>
                        </template>
                      </Promised>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import {
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { Promised } from 'vue-promised'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BBadge,
    BButton,
    BCard,
    BFormTextarea,
    BFormFile,
    BCardHeader,
    BInputGroup,
    BInputGroupAppend,
    FormWizard,
    TabContent,
    Promised,
  },
  data() {
    return {
      status: '',
      selectStartup: Number.parseInt(this.$route.params.aid, 10),
      currentApplication: '',
      title: '',
      subtitle: '',
      incubatorid: '',
      applicationData: [],
      mutationLoading: false,
      files: {},
      startupName: '',
    }
  },
  computed: {
    readonly() {
      if (this.applicationData[0] && this.applicationData[0].status !== 'enabled') {
        return true
      }
      if (this.$ability.can('manage', 'Incubator')) {
        return true
      }
      return this.$route.query.readonly === 'true'
    },
    questions() {
      return this.prepareQuestions()
    },
    answers() {
      const arr = []
      if (this.applicationData[0]) {
        this.applicationData[0].programs_applicationsquestionstables.forEach(r => {
          if (r.programs_applicationresponsetables.length > 0) {
            arr.push({ question_id: r.id, ...r.programs_applicationresponsetables[0] })
          }
        })
      }
      return arr
    },
  },
  mounted() {
    if (this.$route.name === 'startup-application-question' && !this.$route.params.aid) this.$refs.selectStartupModal.show()
  },
  methods: {
    prepareQuestions() {
      if (this.applicationData[0]) {
        return this.groupByKey(
          this.applicationData[0].programs_applicationsquestionstables.map(el => {
            if (el.programs_applicationresponsetables.length === 0) {
              el.programs_applicationresponsetables.push({ response: null })
            }
            return el
          }),
          'section',
        )
      }
      return []
    },
    checkFileSize(file, questionId) {
      if (!file) return false
      const size = 1024 * 1024 * 5
      if (file.size > size) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File size should be less than 5MB',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        this.$nextTick(() => {
          this.files[questionId] = null
        })
        return false
      }
      return true
    },

    groupByKey(array, key) {
      return Array.from(array)
        .reduce((hash, obj) => {
          if (obj[key] === undefined) return hash
          return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
        }, {})
    },

  },
  apollo: {
    applicationData: {
      query() {
        return gql`
        query  getApplicationData($fid: Int!, $org_id: Int!) {
          programs_applicationformstable(where: {id: {_eq: $fid}}) {
            id
            description
            review_status
            status
            title
            users_organizationtable {
              id
              title
            }
            programs_applicationsquestionstables(order_by: {id: asc}) {
              id
              input_type
              options
              question
              section
              is_required
              programs_applicationresponsetables(where: {programs_applicantstable: {organization_id_id: {_eq: $org_id }}}) {
                id
                applicant_id
                response
              }
            }
          }
        }`
      },
      variables() {
        return {
          fid: this.$route.params.fid,
          org_id: this.$route.params.aid,
        }
      },
      update: data => data.programs_applicationformstable,
    },
    startupName: {
      query() {
        return gql`
         query GetStartupName($fid: Int!) {
            programs_applicantstable(where: {current_application_form_id: {_eq: $fid}}) {
              id
              users_organizationtable {
                title
              }
            }
        }
       `
      },
      variables() {
        return {
          fid: this.$route.params.fid,
        }
      },
      update(data) {
        return data.programs_applicantstable[0].users_organizationtable.title
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
